:global {
  .mol-ads-cmp {
    font-family: Arial, sans-serif;

    button {
      font-size: 16px;
      cursor: pointer;
    }

    a {
      text-decoration: none;
      cursor: pointer;
    }

    .mol-ads-cmp--tab {
      font-size: 14px;
    }

    .mol-ads-cmp--banner {
      background-color: #585858;
      color: #fff;
      box-sizing: border-box;

      .mol-ads-cmp--button-privacy-link-group .mol-ads-cmp--btn-primary {
        background-color: #0f9;
        color: #363636;

        &:hover {
          background-color: #00e68a;
        }
      }
    }

    .mol-ads-cmp--cookie-settings {
      border: 1px solid #fff;
      background: #585858;
      color: #fff;

      &:hover {
        background: #4f4f4f;
      }
    }

    .mol-ads-cmp--modal-dialog,
    .mol-ads-cmp--tab-body {
      box-sizing: border-box;
    }

    @media (min-width: 601px) and (max-width: 879px) {
      .mol-ads-cmp--banner {
        bottom: 0;

        .mol-ads-cmp--buttons {
          padding-left: 20px;
          box-sizing: border-box;
        }

        .mol-ads-cmp--cookie-settings {
          height: 50px;
          margin-bottom: 20px;
        }

        .mol-ads-cmp--btn-primary {
          height: 50px;
        }
      }
    }
  }
}
