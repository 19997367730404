:global {
  .footer {
    display: flex;
    min-height: 70px;
    border-top: 1px solid #f5f5f5;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #000;
    font-family: Arial, sans-serif;
    box-sizing: border-box;

    .copyright {
      font-weight: bold;
      font-size: 14px;
    }

    .link {
      color: #000;
      text-decoration: none;

      &:hover {
        color: #666;
      }
    }
  }

  .emailImage {
    display: inline-block;
    width: 109px;
    height: 16px;
    background: url(./assets/emailImage.svg);
    background-repeat: no-repeat;
    background-size: 109px 16px;
    background-position: center;
    vertical-align: middle;
  }

  @media (max-width: 767px) {
    .footer {
      flex-direction: column;
      padding: 32px;

      .link {
        display: block;
        margin-top: 24px;
      }
    }
  }

  @media (min-width: 768px) {
    .page {
      min-height: calc(100vh - 70px);
    }

    .footer {
      flex-direction: row;
      height: 70px;

      .copyright {
        margin-right: 24px;
      }

      .link {
        &:not(:first-of-type) {
          margin-left: 8px;
          padding-left: 8px;
          border-left: 1px solid #000;
        }
      }
    }
  }
}
