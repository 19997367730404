:global {
  body {
    margin: 0;
    padding: 0;
  }

  #mol-ads-cmp-iframe {
    position: absolute;
  }
}

.appWrapper {
  font-family: Arial, sans-serif;
}

.error {
  padding: 8px;
  margin: 8px;
  font-size: 18px;
  color: #d33;
  background: #fcc;
}

.suggestionContainer {
  font-size: 18px;
}

.suggestionText {
  font-style: italic;
}

.suggestionLink {
  font-style: italic;
  color: #00278e;
  cursor: pointer;

  &:hover {
    color: #4772bd;
  }
}

.resultWrapper {
  display: flex;
  flex-direction: row;
}

.splashStoriesWrapper {
  margin-top: 60px;
}

.layoutButtons {
  position: absolute;
  top: 0;
  right: 0;

  button {
    background-color: transparent;
    border: none;
    color: #d8d8d8;
    cursor: pointer;
    width: 27px;
    height: 31px;
    margin-left: 14px;
    padding: 0;
    appearance: none;
    outline: none;

    svg path {
      fill: currentColor;
    }

    &.activeLayout {
      color: #e9124c;
    }

    &:hover {
      color: #f03e6e;
    }
  }
}

.bottomSticky {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
}

:global(body.hasBottomStickyAd) {
  padding-bottom: calc(100vw / 320 * 50 + 10px);

  .bottomSticky {
    bottom: calc(100vw / 320 * 50 + 10px);
  }
}

@media only screen and (max-width: 767px) {
  .appWrapper:not(.desktopInMobileView) {
    .resultWrapper {
      display: block;
    }

    .homePreviewsWrapper {
      width: calc(100% - 32px);
      margin: auto;
      box-sizing: border-box;
    }
  }

  .appWrapper.desktopInMobileView {
    .resultWrapper {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      padding-top: 6px;
      position: relative;
    }

    .homePreviewsWrapper {
      width: 32%;
      flex-shrink: 1;
      margin-right: 10px;
    }
  }

  .splashStoriesWrapper {
    display: block;
    margin: 0 16px;
  }

  .layoutButtons {
    display: none;
  }

  .app.fullScreenSearch .bottomSticky {
    right: 26px;
  }

  .app:not(.fullScreenSearch) .bottomSticky {
    right: 20px;
  }

  .suggestionContainer {
    margin: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .resultWrapper {
    display: flex;
    margin-top: 34px;
    justify-content: space-between;
    padding-top: 6px;
    position: relative;
  }

  .homePreviewsWrapper {
    width: 300px;
    flex-shrink: 0;

    .homesGridLayout & {
      width: 100%;
    }
  }

  .app:not(.fullScreenSearch) {
    width: 740px;
    margin: auto;
    margin-top: 0;
  }

  .app.fullScreenSearch {
    width: 740px;
    margin: auto;
  }

  .topStoriesWrapper {
    margin-top: 36px;
  }
}

@media only screen and (min-width: 964px) {
  .app:not(.fullScreenSearch),
  .app.fullScreenSearch {
    width: 960px;
  }
}

@media only screen and (min-width: 1264px) {
  .app.fullScreenSearch {
    width: 1200px;
  }

  .app:not(.fullScreenSearch) {
    width: 1260px;
  }
}

@media only screen and (min-width: 1600px) {
  .app.fullScreenSearch {
    width: auto;
    max-width: calc(85%);
    min-width: calc(1440px);
    margin: 0 auto auto;
  }

  .app.homesGridLayout {
    width: calc(100% - 128px);
    margin: 0 auto auto;
  }
}
